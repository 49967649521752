// BASE MAIN
body
  font-family: Poppins
  margin: 0
  background-color: $color-white

li
  list-style: none

a
  text-decoration: none


// Animación entrada a página
.animation-recharge
  animation-name: aniEntrance
  animation-duration: 3s
  animation-fill-mode: forwards
// ENTERPRISE DESKTOP

// Enterprise
.enterprise
  margin: 5rem 0
  background: $bg-degraded
  border-radius: 0 0 150px 150px
  &__container
    padding: 5rem 10rem 0 10rem
  &__flex
    display: grid
    grid-gap: 3rem
    grid-template-columns: 1fr 1fr
    align-items: center
  &__text
    h1
      margin: 0 0 3rem 0
      color: $color-yellow
      font-size: $main-title
    p
      text-align: left
      font-size: 1rem
      color: $color-gray-text
      margin-bottom: 4rem
  &__list
    margin: 0 0 0 auto
    &-item
      display: flex
      img
        width: 1.5rem
        margin-right: 1rem
  &__img
    text-align: center
    img
      width: 20rem
      margin-bottom: -0.5rem


// Info

.info
  margin: 10rem 0
  &__card-left
    padding: 5rem 0 3rem 10rem
    display: grid
    grid-template-columns: 30% 1fr
    grid-gap: 10rem
    align-items: center
    &--text
      img
        width: 5rem
      h2
        color: $color-blue
        font-size: $main-title
    &--img
      img
        width: 100%
        border-radius: 100px 0 0 0
  &__card-right
    padding: 5rem 10rem 3rem 0
    display: grid
    grid-template-columns: 1fr 30%
    grid-gap: 10rem
    text-align: right
    align-items: center
    &--text
      img
        width: 4rem
      h2
        color: $color-blue
        font-size: $main-title
    &--img
      img
        width: 100%
        border-radius: 0 100px 0 0

// Solicita

.solicita
  background: $bg-degraded-nd
  border-radius: 150px 150px 0 0
  margin-bottom: -10rem
  &__container
    text-align: center
    padding: 10rem 20rem 20rem 20rem
  &__flex
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    &-img
      text-align: center
      img
        width: 10rem
    &-text
      text-align: left
      h2
        font-size: $main-title
        color: $color-yellow
// HOME DESKTOP

//Landing
.landing
  margin: 5rem 0
  padding: 5rem 0 10rem 0
  background: $bg-degraded
  border-radius: 0 0 150px 150px
  &__container
    display: grid
    grid-template-columns: 40vw 40vw
    grid-gap: 5rem
    justify-content: center
    align-items: center
  &__animation
    padding-top: 2rem
  &__text
    text-align: right
    padding-top: 3rem
    margin-right: auto
    h1
      color: $color-yellow
      font-size: $main-title
    p
      text-align: right
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 3rem


// Benefits

.benefits
  &__container
    padding: 3rem 5rem 3rem 10rem
  &__title-h2
    font-weight: 300
    text-align: center
    font-size: $main-title
    color: $color-gray-text
    margin-bottom: 10rem
    padding: 0 5rem
  &__card-left
    margin-top: 5rem
    width: 60%
    display: flex
    align-items: center
    position: relative
    &--icon
      z-index: 1
      position: absolute
      left: -10%
      @media (min-width: 1900px)
        left: -9%
      @media (min-width: 2000px)
        left: -8%
      img
        background-color: $color-white
        width: 5.5rem
        box-shadow: 0 0 46px #00000029
        border-radius: 15px
        padding: 1rem
    &--text
      box-shadow: 0 0 46px #00000029
      padding: 1rem 3rem 1rem 5rem
      border-radius: 30px
      background-color: $color-white
      h2
        font-size: $nd-title
        color: $color-blue
      p
        color: $color-gray-text
  &__card-right
    margin: 5rem 0 0 auto
    width: 60%
    display: flex
    align-items: center
    position: relative
    &--icon
      z-index: 1
      position: absolute
      right: 95%
      img
        background-color: $color-white
        width: 5.5rem
        box-shadow: 0 0 46px #00000029
        border-radius: 15px
        padding: 1rem
    &--text
      box-shadow: 0 0 46px #00000029
      padding: 1rem 3rem 1rem 5rem
      border-radius: 30px
      background-color: $color-white
      h2
        font-size: $nd-title
        color: $color-blue
      p
        color: $color-gray-text
  &__talki
    margin-top: 15rem
    text-align: center
    h2
      padding: 0 12rem 0 12rem
      font-size: $main-title
      color: $color-yellow
    p
      font-size: 1rem
      color: $color-gray-text
      padding: 0 15rem 3rem 15rem
    &-button
      display: flex
      justify-content: center
      align-items: flex-start
      img
        width: 10rem

// Freetrial

.freetrial
  margin-top: -6rem
  background: $bg-degraded-nd
  border-radius: 150px 150px 0 0
  &__container
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: flex-start
    padding: 15rem 0 10rem 3rem
  &__text
    padding: 0 5rem
    h2
      font-size: $main-title
      color: $color-yellow
    p
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 3rem
  &__mockup
    img
      width: 100%


// Plans

.plans
  &__container
    padding: 5rem 6rem 3rem 3rem
    display: grid
    grid-template-columns: 25% 25% 25% 25%
    grid-template-rows: 11rem 1fr 5rem
    grid-column-gap: 1rem
  &__basic
    box-shadow: 0 0 46px #00000029
    grid-row: 2/2
    grid-column: 1/1
    padding-bottom: 3rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 50px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-blue
        font-size: 1.5rem
        text-transform: uppercase
      hr
        height: .25rem
        background-color: $color-blue
        border: none
      p
        font-size: 1.25rem
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 3rem 1fr
      align-items: center
      img
        margin: 0 auto
        width: 2rem
      p
        padding-left: 1.5rem
  &__advance
    box-shadow: 0 0 46px #00000029
    grid-row: 2/2
    grid-column: 2/2
    padding-bottom: 3rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 50px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-green-avanzado
        font-size: 1.5rem
        text-transform: uppercase
      hr
        height: .25rem
        background-color: $color-green-avanzado
        border: none
      p
        font-size: 1.25rem
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 3rem 1fr
      align-items: center
      img
        width: 2rem
      p
        padding-left: 1.5rem
  &__label
    grid-row: 1/2
    grid-column: 3/4
    &-talki
      img
        position: relative
        left: 27%
        bottom: -.5rem
        width: 10rem
        @media (min-width: 1600px)
          left: 47%
    &-text
      box-shadow: 0 0 46px #00000029
      background-color: $color-pink-premium
      border-radius: 50px 50px 0 0
      text-align: center
      color: $color-white
      font-size: 1.25rem
      padding: .5rem 0
  &__premium
    box-shadow: 0 0 46px #00000029
    grid-row: 2/4
    grid-column: 3/4
    padding-bottom: 10rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 0 0  50px 50px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-pink-premium
        font-size: 1.5rem
        text-transform: uppercase
      hr
        height: .25rem
        background-color: $color-pink-premium
        border: none
      p
        font-size: 1.25rem
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 3rem 1fr
      align-items: center
      img
        width: 2rem
      p
        padding-left: 1.5rem
  &__enterprise
    box-shadow: 0 0 46px #00000029
    grid-row: 2/2
    grid-column: 4/4
    padding-bottom: 3rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 50px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-yellow-enterprise
        font-size: 1.5rem
        text-transform: uppercase
      hr
        height: .25rem
        background-color: $color-yellow-enterprise
        border: none
      p
        font-size: 1.25rem
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 3rem 1fr
      align-items: center
      img
        width: 2rem
      p
        padding-left: 1.5rem

.disabled
  color: $color-gray-nd
.bold
  font-weight: 600

//LOGOS

.logos
  margin: 10rem auto
  &__container
    padding: 10rem 4rem 3rem 4rem
  &__box
    padding: 8rem
    background-color: $color-gray-box
    border-radius: 50px
    &-text
      display: grid
      grid-template-columns: 1fr 1fr
      ul
        margin: 0
      p
        margin: 0
    &-img
      margin-top: 5rem
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      img
        width: 10rem
        height: 100%
        margin: 0 1rem
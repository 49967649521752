// FOOTER HOME

.footer
  background-color: $color-gray
  color: $color-gray-light
  border-radius: 0 100px 0 0
  &__container
    display: flex
    justify-content: space-between
    align-items: center
    padding: $container-desktop-footer
  &__links
    ul
      display: flex
      justify-content: space-between
      li
        margin: 0 1rem
        a
          color: $color-gray-light
          &:hover
            transition: .5s
            color: $color-white
  &__logo
    display: flex
    align-items: center
    a
      img
        width: 10rem
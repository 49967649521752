// PLATFORM DESKTOP

// Platform
.platform
  margin: 5rem 0
  padding: 5rem 0 10rem 0
  background: $bg-degraded
  border-radius: 0 0 150px 150px
  &__container
    display: grid
    grid-template-columns: 40vw 40vw
    grid-gap: 3rem
    justify-content: center
    align-items: center
  &__mockup
    img
      width: 100%
  &__text
    h1
      margin: 0 0 3rem 0
      color: $color-yellow
      font-size: $main-title
    p
      text-align: left
      font-size: 1rem
      color: $color-gray-text
      margin-bottom: 4rem

// Mockups

.mockups
  &__container
    padding: 3rem 2rem 3rem 2rem
  &__card
    &-left
      margin: 15rem auto
      position: relative
      &--flex
        display: flex
      &--img
        margin-left: 35%
        img
          border-radius: 40px
          width: 100%
      &--icon
        z-index: 1500
        position: absolute
        left: 0
        top: -6rem
        img
          background-color: $color-white
          width: 5.5rem
          box-shadow: 0 0 46px #00000029
          border-radius: 15px
          padding: 1rem
      &--text
        width: 40%
        position: absolute
        margin-left: 4%
        top: -8rem
        z-index: 1000
        box-shadow: 0 0 46px #00000029
        padding: 1rem 3rem 1rem 6rem
        border-radius: 30px
        background-color: $color-white
        h2
          color: $color-blue
        p
          color: $color-gray-text
    &-right
      position: relative
      &--flex
        display: flex
      &--img
        margin-right: 35%
        img
          border-radius: 40px
          width: 100%
      &--icon
        z-index: 1500
        position: absolute
        top: -5rem
        right: 46%
        @media (min-width: 1900px)
          right: 45%
        @media (min-width: 2000px)
          right: 44%
        img
          background-color: $color-white
          width: 5.5rem
          box-shadow: 0 0 46px #00000029
          border-radius: 15px
          padding: 1rem
      &--text
        position: absolute
        right: 0
        top: -8rem
        width: 40%
        z-index: 1000
        box-shadow: 0 0 46px #00000029
        padding: 1rem 3rem 1rem 5rem
        border-radius: 30px
        background-color: $color-white
        h2
          color: $color-blue
        p
          color: $color-gray-text

// Contacto

.contact
  background: $bg-degraded-nd
  border-radius: 150px 150px 0 0
  margin-bottom: -10rem
  &__container
    text-align: center
    padding: 5rem 12rem 20rem 12rem
  &__text
    h2
      font-size: $main-title
      color: $color-yellow
    p
      font-size: 1rem
      color: $color-gray-text
      margin-bottom: 3rem
      padding: 0 15rem
  &__button
    display: flex
    justify-content: center
    align-items: flex-start
  &__img
    margin-top: 2rem
    img
      width: 10rem
// PLAN DESKTOP

// Plans page
.plansPage
  margin: 5rem 0
  background: $bg-degraded
  border-radius: 0 0 150px 150px
  &__text
    padding: 5rem 5rem 3rem 9rem
    h1
      color: $color-yellow
      font-size: $main-title
    p
      width: 50%
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 3rem


// COMPARA

.compare
  &__container
    padding: 3rem 10rem
  &__title
    h2
      color: $color-yellow
      font-size: $main-title
      padding-bottom: 3rem
  &__table
    &-title
      color: $color-gray-text
      text-align: center
      font-weight: 600
      display: grid
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr
      &-1
       grid-column: 2/3
      &-2
        grid-column: 3/4
      &-3
        grid-column: 4/5
      &-4
        grid-column: 5/6
    &-user, &-chats
      font-weight: 600
      text-align: center
      display: grid
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr
      border-bottom: 1px solid $color-gray-light
      &-1
        color: $color-gray-text
        font-weight: 600
      &-2, &-3, &-4, &-5
        color: $color-blue
    &-bigdata, &-machine, &-estadisticas
      border-bottom: 1px solid $color-gray-light
      color: $color-gray-text
      text-align: center
      font-weight: 600
      display: grid
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr
      align-items: center
      &--icon
        img
          width: 1.25rem
    &-precios
      font-weight: 600
      text-align: center
      display: grid
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr
      &-1
        color: $color-gray-text
        font-weight: 600
      &-2, &-3, &-4, &-5
        color: $color-blue
    &-btn
      margin-top: 1rem
      text-align: center
      font-weight: 600
      display: grid
      grid-gap: 1rem
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr
      &-1
        grid-column: 2/3
      &-2
        grid-column: 3/4
      &-3
        grid-column: 4/5
      &-4
        grid-column: 5/6


// FAQ

.faq
  margin: 5rem 0
  &__container
    padding: 3rem 10rem
  &__title
    h2
      color: $color-yellow
      font-size: $main-title
      padding-bottom: 3rem
  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 5rem
  &__text
    &-1
      margin-bottom: 4rem
    &-question
      font-weight: 600
      color: $color-gray-text
    &-answer
      color: $color-gray-text


// SUPPORT

.support
  background: $bg-degraded-nd
  border-radius: 150px 150px 0 0
  &__container
    padding: 3rem 10rem
    margin: 0 auto
  &__title
    text-align: center
    h2
      color: $color-yellow
      font-size: $main-title
      padding-bottom: 2rem
  &__text
    text-align: center
    p
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 2rem
    &-flex
      display: flex
      justify-content: center
      align-items: flex-start
  &__img
    margin-top: 1rem
    img
      width: 10rem

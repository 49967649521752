// MAIN FONTS

@font-face
  font-family: "Poppins"
  src: url("../fonts/Poppins-Bold.woff2") format("woff2")
  font-weight: 600

@font-face
  font-family: "Poppins"
  src: url("../fonts/Poppins-Medium.woff2") format("woff2")
  font-weight: 500

@font-face
  font-family: "Poppins"
  src: url("../fonts/Poppins-Regular.woff2") format("woff2")
  font-weight: 300



// BUTTON DESKTOP

.btn
  border-radius: 2.5rem
  padding: .5rem 1.25rem
  box-shadow: 0 3px 6px $color-gray-shadow
  &-blue
    border: 1px solid $color-blue
    color: $color-blue
    &:hover
      transition: .5s
      background-color: $color-blue
      color: $color-white
  &-blue-table
    background-color: $color-blue
    color: $color-white
    &:hover
      background-color: $color-blue-hover
  &-gray
    border: 1px solid $color-gray
    color: $color-gray
    &:hover
      transition: .5s
      background-color: $color-gray
      color: $color-white
// HEADER DESKTOP HOME

.header
  position: fixed
  top: 0
  right: 0
  left: 0
  z-index: 5000
  font-family: Poppins
  background-color: $color-white
  &__container
    display: grid
    grid-template-columns: 15rem 30rem 1fr
    align-items: center
    justify-content: space-between
    padding: 0 2rem
    box-shadow: 0 3px 6px $color-gray-shadow
  &__logo
    img
      width: $logo-menu-dt
  &__menu
    ul
      display: flex
      justify-content: space-between
      margin: .75rem
      li
        margin: 1rem 2rem 0 2rem
        a
          position: relative
          overflow: hidden
          display: inline-block
          text-decoration: none
          color: $color-gray
          p
            border-bottom: .2rem solid transparent
            margin: 0
            &:after
              content: ""
              position: absolute
              bottom: 0
              left: -100%
              width: 100%
              height: 2px
              background: $color-yellow
              transition: left .5s
            &:hover:after
              left: 0




      hr
        display: none
    &-icon
      img
        display: none
  &__buttons
    display: flex
    justify-content: flex-end
    align-items: center
    a
      margin: 0 1rem
      color: $color-blue
    p
      margin: 0

.dt-none
  display: none

.scroll-down
  transform: translate3d(0, -100%, 0)
  transition: 1s

.scroll-up
  transform: none
  transition: 1s

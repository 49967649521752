// MAIN VARIABLES

// COLORS

$color-white: #FFFFFF
$color-blue: #0494C0
$color-blue-hover: #0F7493
$color-gray-shadow: #00000029
$color-gray-menu: #F3F3F3
$color-gray-box: #F5F5F5
$color-gray-nd: #9A9A9A
$color-gray-light: #BCBCBC
$color-gray: #707070
$color-gray-text: #3D3D3D
$color-yellow: #FFD551
$color-green-avanzado: #0A8165
$color-pink-premium: #C25D96
$color-yellow-enterprise: #FBB850

$bg-degraded: linear-gradient(#FFFF, #e4e4e4)
$bg-degraded-nd: linear-gradient(#e4e4e4, #FFFF)

// MARGINS & PADDINGS

$container-menu-desktop: 0 6rem
$container-menu-mb: .75rem 1rem .25rem 1rem
$container-desktop: 10rem 10rem 3rem 10rem
$container-tablet: 3rem 5rem
$container-desktop-footer: 5rem
$container-mb-footer: 2rem 2rem

// FONT SIZE
//desktop
$main-title: 2rem
$nd-title: 1.75rem
//mobile
$main-title-mb: 1.5rem
$nd-title-mb: 1.25rem
$text-mb: 0.9rem
//tablet
$main-title-tb: 2rem
$nd-title-tb: 1.5rem
$text-tb: 1.2rem


// LOGO SIZE
$logo-menu-dt: 6rem
$logo-menu-mb: 6rem